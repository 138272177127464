<script setup>
</script>
<template>
  <body>
    <br>

    <br>
    <div class="grid-container">
      <div class="title1"><span>ผลิตภัณฑ์ของเรา</span></div>
      <!-- CAR -->
      <div class="card">
        <img
          src="@/assets/cars/110_1.png"
          class="card__img"
        />
        <span class="card__title">ประกันรถยนต์</span>
        <div class="card__content">
          <p class="card__sizeTitle">ประเภท</p>
          <div class="card__sizeContainer">
            <span class="card__sizeNumber">1</span>
            <span class="card__sizeNumber">2+</span>
            <span class="card__sizeNumber">2</span>
            <span class="card__sizeNumber">3+</span>
            <span class="card__sizeNumber">3</span>
          </div>
          <br>
        </div>
      </div>
      <!-- Health -->
      <div class="card">
        <img
          src="@/assets/picture/health2.png"
          class="card__img"
        />
        <h2 class="card__title" style="margin-top: 10px;">ประกันสุขภาพ</h2>
        <div class="card__content">
          <p class="card__sizeTitle">แผนประกัน</p>
          <div class="card__sizeContainer">
            <span class="card__menu">เบสิคแคร์</span>
            <span class="card__menu">สมาร์ทเฮลท์</span>
            <span class="card__menu">ซูพีเรียเฮลท์</span>
          </div>
          <br>
        </div>
        <router-link to="/health" class="link"><button class="card__link" role="button">แผนอื่นๆ</button></router-link>
        <!-- <a href="#" class="card__link"></a> -->
      </div>
      <!-- Travel -->
      <div class="card">
        <img
          src="@/assets/ads/pa.png"
          class="card__img"
        />
        <h2 class="card__title" style="margin-top: 10px;">ประกันเดินทาง</h2>
        <div class="card__content">
          <p class="card__sizeTitle">แผนประกัน</p>
          <div class="card__sizeContainer">
            <span class="card__menu">ภายในประเทศ</span>
            <span class="card__menu">ต่างประเทศ</span>

          </div>
          <br>
        </div>
        <!-- <a href="#" class="card__link">แผนอื่นๆ</a> -->
      </div>
    </div>
  </body>
</template>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&family=Noto+Sans+Thai:wght@100..900&display=swap');

.noto-sans-thai-<uniquifier> {
  font-family: "Noto Sans Thai", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
:root {
  --color1: #31304D;
  --color2: #fff;
  --transitionDuration: 1s;
}

/* Reset Default Settings */
* {
  box-sizing: border-box;
  /* margin: 0; */
}

body {
  background-color: #ffff;
  min-height: 85vh;
  font-family: "Noto Sans Thai";
}
.card__sizeTitle {
  font-weight: 500;
  font-size: 16px;
}
.title1 {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  background: -webkit-linear-gradient(#161A30, #48556b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.grid-container {
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.card {
  background-color: #31304D;
  color: #fff;
  width: 320px;
  height: 450px;
  letter-spacing: 1px;
  border-radius: 20px;
  border: #bebcb7;
  overflow: hidden;
  position: relative;
  /* Flex */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardCar {
  background-color: #31304D;
  color: #fff;
  width: 320px;
  height: 450px;
  letter-spacing: 1px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  /* Flex */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 'ISR' label in the background */
.card::before {
  color: #ffffff;
  width: fit-content;
  height: fit-content;
  font-size: 200px;
  font-weight: bold;
  font-style: italic;
  opacity: 15%;

  content: "ISR";
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
}

/* Circle */
.card::after {
  background-color:#F0ECE5;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  z-index: 0;

  /* Transition */
  transition-duration: calc(var(--transitionDuration) / 2);
  transition-property: width, height, transform;
  transition-timing-function: ease-in-out;

  content: "";
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(45%, -45%);
}

.card:hover::after {
  width: 500px;
  height: 500px;
  transform: translate(30%, -62%);
}

.card__img {
  min-width: 250px;
  max-height: 250px;
  z-index: 1;
  transition: top var(--transitionDuration);
  border-radius: 30%;

  /* Position */
  position: absolute;
  left: 35%;
  top: 45%;
  transform: translate(-50%, -50%) ;
}

.card:hover .card__img {
  top: 24%;
}

.card__title {
    width: 300px;
    font-size: 2rem;
  font-weight: 600;
  transition: top var(--transitionDuration);
  color: #d7bf49;
  /* Position */
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translateX(-50%);
}

.card:hover .card__title {
  top: 50%;
}

.card__content {
  height: 70px;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  opacity: 0;
  transition: top var(--transitionDuration),
    opacity var(--transitionDuration) calc(var(--transitionDuration) / 2);

  /* Position */
  position: absolute;
  left: 50%;
  top: 150%;
  transform: translate(-50%, -50%);

  /* Flex */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card:hover .card__content {
  opacity: 100%;
  top: 70%;
}

.card__sizeContainer {
  /* Flex */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__sizeNumber {
  background-color: #fff;
  color: #31304D;
  width: 45px;
  height: 45px;
  padding: 5px 7px;
  margin: 0 5px;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;

  /* Flex */
  display: flex;
  justify-content: center;
  align-items: center;
}
.card__menu {
  background-color: #fff;
  font-family: "Noto Sans Thai";
  color: #31304D;
  font-weight: 500;
  width: 90px;
  height: 45px;
  padding: 5px 7px;
  margin: 0 5px;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  /* Flex */
  display: flex;
  justify-content: center;
  align-items: center;
}
.card__sizeNumber:hover {
  background-color: #ffed4b;
}

.card__colorContainer {
  /* Flex */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__colorCircle {
  background-color: #fff;
  color: #31304D;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}

.card__colorCircle:hover {
  outline: solid 2px #fff;
}

.card__link {
  background-color: #fff;
  color: #31304D;
  padding: 10px 15px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 3px;
  transition: top var(--transitionDuration);

  /* Position */
  position: absolute;
  left: 50%;
  top: 250%;
  transform: translate(-50%, -50%);
}

.card:hover .card__link {
  top: 88%;
}

.card__link:hover {
  background-color: #ffed4b;
}
.card__menu:hover {
  background-color: #ffed4b;
}
@media screen and (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    /* margin-leftft: 30px; */
  }
  .card {
    justify-content: center;
    left: 20%;
  }
}
@media screen and (max-width: 871px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    /* margin-leftft: 30px; */
  }
  .card {
    justify-content: center;
    left: 10%;
  }
}
@media screen and (max-width: 819px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    /* margin-left: 20px; */
  }
  .card {
    left: 30%;
  }
}
@media screen and (max-width: 650px) {
  .card {
    left: 20%;
  }
}
@media screen and (max-width: 500px) {
  .card {
    left: 10%;
  }
}
@media screen and (max-width: 360px) {
  .card {
    left: 3%;
  }
}
</style>
