<template>
  <header :class="{ 'scrolled-nav': scrolledNav }">
    <nav>
      <div class="grid-container">
        <router-link to="/" >
        <div class="branding">
          <img src="@/assets/isr_logo.png" alt="" />
        </div>
      </router-link>
        <h3 style="font-family: mitr, cursive;">ISARA</h3>
        <div></div>
        <div></div>
        <div></div>
        <div class="fontnav" style="margin-top: -35px; font-family: mitr, cursive;">บริษัท อิสระอินเตอร์ โบรคเกอร์</div>
      </div>
      <ul v-show="!mobile" class="navigation">
        <li>
          <router-link to="/" class="link">หน้าหลัก</router-link>
        </li>
        <li>
          <router-link to="/about" class="link">เกี่ยวกับเรา</router-link>
        </li>
        <li>
          <!-- <router-link to="/about" class="link">about</router-link> -->
        </li>
        <!-- <router-link to="/premium" class="link"><button class="button-85" role="button">เช็คเบี้ย</button></router-link> -->
      </ul>

      <div class="icon">
        <i
          @click="toggleMobileNav"
          v-show="mobile"
          class="far fa-bars"
          :class="{ 'icon-active': mobileNav }"
        ></i>
      </div>
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav">
          <li>
          <router-link to="/" class="link">หน้าหลัก</router-link>
        </li>
        <li>
          <router-link to="/about" class="link">เกี่ยวกับเรา</router-link>
        </li>
        <!-- <router-link to="/premium" class="link"><button class="button-85" role="button">เช็คเบี้ย</button></router-link> -->
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
export default {
  name: "MyNavbar",
  data() {
    return {
      scrolledNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },

    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
  },
  
};
</script>

<style scoped>
header {
  background-color: #161A30;
  opacity: 400ms;
  z-index: 99;
  width: 100%;
  position: fixed;
  transition: 0.5s ease all;
  color: white;

  nav {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 4px 0;
    transition: 0.5s ease all;
    width: 90%;
    margin: 0 auto;
    @media (min-width: 1140px) {
      max-width: 1140px;
    }
    ul,
    .link {
      font-weight: 500;
      color: white;
      list-style: none;
      text-decoration: none;
    }
  }
  li {
    text-transform: uppercase;
    padding: 16px;
    margin-left: 16px;
  }
  .link {
    font-size: 14px;
    transition: 0.5s ease all;
    padding-bottom: 4px;
    border-bottom: 1px solid transparent;

    &:hover {
      color: rgb(201, 141, 0);
      border-color: rgb(255, 255, 255);
    }
  }
  .branding {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      transition: 0.5s ease all;
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }
  .icon {
    display: flex;
    position: absolute;
    align-items: center;
    top: 0;
    right: 24px;
    height: 100%;

    i {
      cursor: pointer;
      font-size: 24px;
      transition: 0.8s ease all;
    }
  }

  .icon-active {
    transform: rotate(180deg);
  }
  .dropdown-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    max-width: 250px;
    height: 100%;
    background-color: aliceblue;
    top: 0;
    left: 0;

    li {
      margin-left: 0;
      .link {
        color: black;
      }
    }
  }
  .mobile-nav-enter-active,
  .mobile-nav-leave-active {
    transition: 1.5s ease all;
  }
  .mobile-nav-enter-form,
  .mobile-nav-leave-to {
    transform: translateX(-250px);
  }
}

.scrolled-nav {
  background-color: #31304D;
  box-shadow: 0 4px 6px -1px black, 0 2px 4px -1px black;
  opacity: 0px;

  nav {
    padding: 2px 0;
    .branding {
      img {
        width: 70px;
        /* box-shadow: 0 4px 6px -1px #070036, 0 2px 4px -1px black; */
      }
    }
  }
}

/* CSS */
.button-85 {
  font-family: "Mitr", sans-serif;
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(0, 0, 0);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #37037b,
    #5b036f,
    #e04dc5,
    #78018b,
    #6c0063,
    #54036b,
    #7a00ff,
    #9827c8,
    #7b00ff
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: 80px 300px auto auto;
  grid-column-gap: 0.5rem;
  grid-row-gap: -1.5rem;
  font-size: 20px;
  /* border: #ffffff solid; */
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
  @media screen and (min-width: 601px) {
  div.fontnav {
    font-size: 20px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  div.fontnav {
    font-size: 15px;
  }
}
</style>