<template>
  <div v-if="visible" class="modal-overlay" @click="close">
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <!-- Title -->
        <div class="company-logo">
          <img :src="getLogo(packageData.package.company)" alt="Company Logo" class="company-logo" />
        </div>
        <div class="package-details">
          <h3>{{ packageData.package.company }}</h3>
        </div>
        <div class="premium">
          <p>฿{{ packageData.premium_total }}</p>
        </div>
      </div>
      <div class="modal-body">
        <!-- Basic Info -->
        <div class="basic-info">
          <p>ค่าส่วนแรก: {{ packageData.deduct }}</p>
          <p>ประเภท: ชั้น {{ packageData.package.package_type }}</p>
          <p>อู่ซ่อม: {{ translate(packageData.garage) }}</p>
        </div>

        <!-- Coverage Detail -->
        <div class="coverages">
          <div v-for="(coverages, type) in groupedCoverages" :key="type">
            <h5>{{ translate(type) }}</h5>
            <table>
              <tbody>
                <!-- Coverage by Group -->
                <tr v-for="coverage in coverages" :key="coverage.id">
                  <td>{{ translate(coverage.name) }}</td>
                  <td class="coverage_value">{{ getCoverageValue(coverage.value) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button @click="close">Close</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DetailModal',
  props: {
    visible: Boolean,
    packageData: Object
  },
  computed: {
    groupedCoverages() {
      if (!this.packageData) return {};

      return this.packageData.package.coverages.reduce((acc, coverage) => {
        if (!acc[coverage.coverage_type]) {
          acc[coverage.coverage_type] = [];
        }
        acc[coverage.coverage_type].push(coverage);
        return acc;
      }, {});
    }
  },
  // Translate English to Thai by translations dic or return  original value
  methods: {
    translate(key) {
      return translations[key] || key;
    },
    getLogo(companyName) {
      switch (companyName) {
        case 'Chubb':
          return '/chubb_logo.png';
        case 'AXA':
          return '/AXA_logo.png';
        default:
          return ''; // Path to default logo if available
      }
    },
    getCoverageValue(value) {
      return value === 'As per sum insured' ? this.packageData.max_sum_insured : value;
    },
    close() {
      this.$emit('close');
    }
  }
}

const translations = {
  "1. Third party liability": "1. ความรับผิดชอบต่อคู่กรณีสูงสุด",
  "2. Own damage coverage": "2. ความคุ้มครองต่อรถยนต์สูดสุด",
  "3. Additional coverage": "3. ความคุ้มครองต่อบุคคลสูงสุด",
  "4. Optional ": "4. ความคุ้มครองอื่นๆ",
  "1.1 Bodily injury (Baht/Person)": "การเสียชีวิตของบุคคลภายนอก (ต่อคน)",
  "Over maximum limit of compulsory motor insurance only (Baht/Accident)": "การเสียชีวิตของบุคคลภายนอก (สูงสุด)",
  "1.2 Property damage (Baht/Accident)": "ความรับผิดต่อทรัพย์สินบุคคลภายนอก",
  "2.1 Own damage": "ความเสียหายต่อรถยนต์",
  "2.2 Fire and theft": "ไฟไหม้และการโจรกรรม",
  "2.3 Flood": "น้ำท่วม",
  "Deductible": "ค่าเสียหายส่วนแรก",
  "3.1 Personal accident (Baht/Person, Sedan:7/ Van:12/ Pickup:5)": "อุบัติเหตุส่วนบุคคล (ต่อคน)",
  "3.2 Medical expenses (Baht/Person, Sedan:7/ Van:12/ Pickup:5)": "ค่ารักษาพยาบาล (ต่อคน)",
  "3.1 Personal accident (Baht/Person, Sedan:7/ Pickup:5)": "อุบัติเหตุส่วนบุคคล (ต่อคน)",
  "3.2 Medical expenses (Baht/Person, Sedan:7/ Pickup:5)": "ค่ารักษาพยาบาล (ต่อคน)",
  "3.1 Personal accident (Baht/Person, Pickup 4dr:3/ Pickup 2dr:3)": "อุบัติเหตุส่วนบุคคล (ต่อคน)",
  "3.2 Medical expenses (Baht/Person, Pickup 4dr:3/ Pickup 2dr:3)": "ค่ารักษาพยาบาล (ต่อคน)",
  "3.3 Bail bond (Baht/Accident)": "ประกันตัวผู้ขับขี่ (ต่อครั้ง)",
  "Flood, Windstorm, Hail, and Earthquake": "น้ำท่วม, พายุ, ลูกเห็บ, และแผ่นดินไหว",
  "contracted": "ซ่อมอู่",
  "dealer": "ซ่อมห้าง",
  "Contrated": "ซ่อมอู่",
  "Dealer": "ซ่อมห้าง"
};

</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  width: 600px;
  overflow-y: auto;
  max-height: 80%;
  box-sizing: border-box;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-logo img {
  width: 60px;
  height: 60px;
}

.package-details {
  flex-grow: 1;
  padding-left: 10px;
}

.premium {
  font-size: 22px;
  font-weight: 600;
}

.modal-body {
  margin-top: 20px;
}

.basic-info p {
  margin: 0 0 10px 0;
}

.coverages {
  margin-top: 20px;
}

.coverages h5 {
  margin: 10px 0;
  font-size: 18px;
}

.coverages table {
  width: 100%;
  border-collapse: collapse;
}

.coverages table td {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
}

.coverage_value {
  text-align: right;
}

button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #161A30;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #1e2341;
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    max-width: none;
    max-height: 90%;
  }
}

@media (max-width: 480px) {
  .modal-content {
    padding: 15px;
  }
}
</style>