<script setup>
  import 'bootstrap/dist/css/bootstrap.min.css';
</script>
  <template>
  <!---------------------------------------------------- FOOTER -------------------------------------------------------------->
  <footer class="footer">
		<div class="container row">
			<div class="footer-col1">
			<img src="@/assets/isr_logo.png" class="flogo" alt="" style="width: 60px;"/>
        <p style="color: white;">บริษัท อิสระ อินเตอร์ โบรคเกอร์ จำกัด <br>50/17 ม.1 ต.บ้านสวน อ.เมือง จ.ชลบุรี</p>
        <!-- <P><a href="#">เกี่ยวกับเรา</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="#">รางวัล</a></P> -->
			</div>
			<!-- <div class="footer-col">
				<h4>ประกันวินาศภัย</h4>
				<ul>
					<li><a href="#">ประกันรถยนต์</a></li>
					<li><a href="#">ประกันสุขภาพ</a></li>
					<li><a href="#">ประกันการเดินทาง</a></li>
					<li><a href="#">ประกันอัคคีภัย</a></li>
					<li><a href="#">ประกันขนส่งทางเรือ</a></li>
				</ul>
			</div> -->
			<div class="footer-col2">
				<h4></h4>
				<ul>
					
				</ul>
			</div>
			<div class="footer-col2">
				<h4></h4>
				<ul>
					
				</ul>
			</div>
			<div class="footer-col">
				<h4 class="follow">follow us</h4>
				<div class="social-links">
					<a href="https://www.facebook.com/isarabroker"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
</svg></a>
					<a href="https://lin.ee/aJts7d3"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-line" viewBox="0 0 16 16">
  <path d="M8 0c4.411 0 8 2.912 8 6.492 0 1.433-.555 2.723-1.715 3.994-1.678 1.932-5.431 4.285-6.285 4.645-.83.35-.734-.197-.696-.413l.003-.018.114-.685c.027-.204.055-.521-.026-.723-.09-.223-.444-.339-.704-.395C2.846 12.39 0 9.701 0 6.492 0 2.912 3.59 0 8 0M5.022 7.686H3.497V4.918a.156.156 0 0 0-.155-.156H2.78a.156.156 0 0 0-.156.156v3.486c0 .041.017.08.044.107v.001l.002.002.002.002a.15.15 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157m.791-2.924a.156.156 0 0 0-.156.156v3.486c0 .086.07.155.156.155h.562c.086 0 .155-.07.155-.155V4.918a.156.156 0 0 0-.155-.156zm3.863 0a.156.156 0 0 0-.156.156v2.07L7.923 4.832l-.013-.015v-.001l-.01-.01-.003-.003-.011-.009h-.001L7.88 4.79l-.003-.002-.005-.003-.008-.005h-.002l-.003-.002-.01-.004-.004-.002-.01-.003h-.002l-.003-.001-.009-.002h-.006l-.003-.001h-.004l-.002-.001h-.574a.156.156 0 0 0-.156.155v3.486c0 .086.07.155.156.155h.56c.087 0 .157-.07.157-.155v-2.07l1.6 2.16a.2.2 0 0 0 .039.038l.001.001.01.006.004.002.008.004.007.003.005.002.01.003h.003a.2.2 0 0 0 .04.006h.56c.087 0 .157-.07.157-.155V4.918a.156.156 0 0 0-.156-.156zm3.815.717v-.56a.156.156 0 0 0-.155-.157h-2.242a.16.16 0 0 0-.108.044h-.001l-.001.002-.002.003a.16.16 0 0 0-.044.107v3.486c0 .041.017.08.044.107l.002.003.002.002a.16.16 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156Z"/>
</svg></a>
					<a href="https://maps.app.goo.gl/K3opmyYd2vKaizc48"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg></a>

				</div>
			</div>
		</div>
	</footer>
  <!---------------------------------------------------- /FOOTER -------------------------------------------------------------->
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      message:""
    }
  },
}

</script>

<style scoped>
/* .theme_used {
	color: #161A30;
	color: #31304D;
	color: #B6BBC4;
	color: #F0ECE5;
} */
* {
  overflow: hidden; /* Hide scrollbars */
  font-family: "Mitr", sans-serif;
}
.grid-container {
  display: grid;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}
@media (min-width: 576px) {
  .grid-container { grid-template-columns: repeat(1, 1fr); }
}
@media (min-width: 992px) {
  .grid-container { grid-template-columns: repeat(3, 1fr); }
}

.footer {
	background-color: #070036;
	padding: 30px 0;
}

.container {
	max-width: 1170px;
	margin: auto;
}

.row {
	display: flex;
	flex-wrap: wrap;
}

ul {
	list-style: none;
}
.footer-col1 {
	width: 50%;
	padding: 0 15px;
}
.footer-col {
	width: 25%;
	padding: 0 15px;
}
.footer-col2 {
	width: 10%;
	padding: 0 15px;
}

.footer-col h4 {
	font-size: 18px;
	color: #FFF;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}

.footer-col h4::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -10px;
	background-color: #E91E63;
	width: 50px;
	height: 2px;
}

.footer-col ul li:not(:last-child) {
	margin-bottom: 10px;
}

.footer-col ul li a {
	color: #DDD;
	display: block;
	font-size: 1rem;
	font-weight: 300;
	text-transform: capitalize;
	text-decoration: none;
	transition: all 0.3s ease;
}

.footer-col ul li a:hover {
	color: #FFF;
	padding-left: 7px;
}
.follow {
	display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
}
.social-links {
	display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}
.footer-col .social-links a {
	color: #FFF;
	background-color: rgba(255, 255, 255, 0.2);
	display: inline-block;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	text-align: center;
	margin: 0 10px 10px 0;
	line-height: 40px;
	transition: all 0.5s ease;
}

.footer-col .social-links a:hover {
	color: #151515;
	background-color: #FFF;
	text-align: center;
}

@media(max-width: 767px) {
	.footer-col1 {
	width: 100%;
	padding: 0 15px;
}
	.footer-col {
		width: 100%;
		margin-bottom: 30px;
	}
	.footer-col2 {
	width: 0%;
	padding: 0 15px;
}
  p {
    text-align: center;
  }
  .flogo {
    margin-left: 40%;
  }
}

@media(max-width: 574px) {
	.footer-col {
		width: 100%;
	}
  .flogo {
    margin-left: 40%;
  }
}

</style>