<template>
  <div class="container">
    <div class="row">
      <div class="card_wrapper1">
        <div class="grid-containerT1">
          <div class="whyT1">
            <h1>
              <span style="font-size: 60px; color: red; padding: 1px">ทำไม</span
              ><br />ต้องซื้อประกันรถยนต์ชั้น 1
            </h1>
          </div>
        </div>
        <div class="grid-containerT1">
          <div class="boxT1">
            <b>คุ้มครองครอบคลุม:</b> ประกันชั้น 1
            ให้ความคุ้มครองสูงสุดทั้งต่อตัวผู้เอาประกัน บุคคลภายนอก
            และรถยนต์ที่เอาประกัน กรณีเกิดอุบัติเหตุ ชน ไฟไหม้ หรือสูญหาย <br />
            <b>หมดกังวล</b>: มั่นใจได้ว่ามีเงินทุนสำรองรองรับค่าซ่อมแซม
            ไม่ต้องกังวลเรื่องภาระค่าใช้จ่าย <br />
            <b>สะดวก</b>: บริการช่วยเหลือฉุกเฉิน 24 ชั่วโมง
            และอู่ซ่อมมาตรฐานทั่วประเทศ
          </div>
        </div>
      </div>
      <div class="card_wrapper">
        <div class="grid-containerT1">
          <div class="card_box">
            <img
              class="img-fluid img_icon"
              style="display: block"
              src="@/assets/icon/crashed2.png"
              alt=""
            />
            <div class="card_text">
              <span class="headText">ความเสียหายจากกรณีรถชน</span>
              <p class="pp">
                คุ้มครองต่อความเสียหายของรถยนต์คันที่ทำประกัน
                เมื่อเกิดเหตุชนทั้งแบบมีคู่กรณีและไม่มีคู่กรณี
              </p>
            </div>
          </div>
          <div class="card_box">
            <img
              class="img-fluid img_icon"
              src="@/assets/icon/3things.png"
              alt=""
            />
            <div class="card_text">
              <span class="headText">ความเสียหายต่อตัวรถ</span>
              <p>
                กรณีรถยนต์สูญหาย ไฟไหม้ และภัยธรรมชาติต่างๆ เช่น น้ำท่วม พายุ
              </p>
            </div>
          </div>
          <div class="card_box">
            <img
              class="img-fluid img_icon"
              src="@/assets/icon/others.png"
              alt=""
            />
            <div class="card_text">
              <span class="headText">ความเสียหายต่อบุคคลภายนอก</span>
              <p>
                คุ้มครองความเสียหายต่อชีวิต ร่างกาย อนามัย
                และทรัพย์สินของคู่กรณีและบุคคลภายนอก
              </p>
            </div>
          </div>
          <div class="card_box box1">
            <img
              class="img-fluid img_icon"
              src="@/assets/icon/incar.png"
              alt=""
            />
            <div class="card_text">
              <span class="headText">ผู้ขับขี่และผู้โดยสาร</span>
              <p>
                คุ้มครองความเสียหายต่ออุบัติเหตุส่วนบุคคลและเงินค่าประกันตัวผู้ขับขี่ในคดีอาญา
              </p>
            </div>
          </div>
        </div>
      </div>
      <!----------------------------------------------------- CARD2 ----------------------------------------------------->
      <!-- <div class="card2_wrapper">
        <div class="grid-containerT2">
          <div class="card_boxL">
            <img class="img-fluid img_icon" style="display: block;"  src="@/assets/icon/crashed.png" alt="">
              <div class="card_text"> 
                <span class="headText">คุ้มครอง<br>ความเสียหายต่อทรัพย์สินในรถ</span>
                <p class="pp">จากอุบัติเหตุชนโดยระบุคู่กรณีได้</p>
              </div>
          </div>
          
          <div class="card_box_Center">
            <img class="img-fluid img_icon"  src="@/assets/icon/fires.png" alt="">
              <div class="card_text"> 
                <span class="headText">คุ้มครอง<br>ความเสียหายต่อทรัพย์สินในรถ</span>
                <p>จากอุบัติเหตุชนโดยระบุคู่กรณีได้</p>
              </div>
          </div>
        
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  font-family: "mitr", cursive;
}
.grid-containerT1 {
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}
.grid-containerT2 {
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto;
}
.whyT1 {
  align-content: center;
  padding-left: 18px;
  text-align: center;
  margin-top: 25px;
  font-family: "mitr", cursive;
  /* text-decoration: underline red; */
  color: #ffffff;
}

.boxT1 {
  border: #161A30 2px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

/* ----------------card---------------- */
.card_wrapper1 {
  background: #31304D;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 3%;
}
.card_wrapper {
  /* background: linear-gradient(180deg, #161A30 46.35%, #31304D 80.01%); */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 3%;
  border-radius: 0px 0px 20px 20px;
}

.headText {
  font-family: "mitr", bold;
  font-size: 30px;
  text-align: center;
}

.head_para {
  margin-top: 15px;
}
.img_icon {
  border-radius: 5px;
  width: 80%;
  margin-left: 10%;
}
.card_box .headText{
  box-shadow: 0px 4px 0px #00000000;
  color: #161A30;
  box-shadow: 20px white;
}
.card_box:hover {
  box-shadow: 0px 4px 0px #0000000d;
  transform: translateY(-10px);
  background-color: white;
  color: rgb(255, 140, 0);
  box-shadow: 20px white;
}


.card_text {
  width: 100%;
  padding: 15px 10px 12px 10px;
  text-align: center;
}
/* .pp {
  color: #ffffff;
} */
b {
  padding: 2px;
  color: #31304D;
  text-decoration: underline rgb(255, 230, 2);
}

.card_text p {
  font-size: 14px;
  line-height: 150%;
  margin-top: 10px;
  color: #161a30;
}

/****************************************************RESPONSIVE****************************************************/

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
  .headText {
    font-size: 20px;
    padding: 0px;
  }

  .boxT1 {
    font-size: 1.1rem;
  }
  b {
    font-size: 1.4rem;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .headText {
    text-align: center;
    font-size: 1rem;
    padding: 0px;
  }
  .boxT1 {
    font-size: 0.8rem;
  }
  b {
    font-size: 1.1rem;
  }
  .grid-containerT1 {
    padding: 10px;
    display: grid;
    grid-template-columns: auto auto;
  }
  .card_boxR {
    background-color: rgba(255, 0, 0, 0);
    /* border-radius: 10px; */
    transition: 0.3s;
    margin: 2px;
    opacity: 50px;
  }
  .card_boxL {
    background-color: rgba(255, 0, 0, 0);
    /* border-radius: 10px; */
    transition: 0.3s;
    margin: 2px;
  }
}
</style>
