<script setup>
/* eslint-disable */
import years from '@/assets/data/year.json'
import { ref, onMounted, watch, computed } from 'vue'
import { useRouter } from 'vue-router';
import axios from 'axios'

const apiUrl = `https://quick-brake-production.up.railway.app/api/`
// Fetch Brands
const brands = ref([])
const fetch_brands = async () => {
  try {
    const response = await axios.get(apiUrl + 'brands')
    brands.value = response.data
  } catch (err) {
    console.log(err)
  }
}
onMounted(() => fetch_brands())

// Fetch Province
const province = ref([])
const fetch_province = async () => {
  try {
    const response = await axios.get(apiUrl + 'province')
    province.value = response.data
  } catch (err) {
    console.log(err)
  }
}
onMounted(() => fetch_province())

const selectedBrand = ref('')
const selectedYear = ref('')
const selectedPackage = ref('')
const selectedProvince = ref('')

// Fetch Models
const selectedModel = ref('')
const carModels = ref([])
const fetch_models = async (brand, year) => {
  brand = brand.brand_code.replace(/ /g, '');
  const queryParams = {
    year: year,
    brand: brand
  };

  try {
    const response = await axios.get(apiUrl + 'cars', { params: queryParams })
    carModels.value = response.data
  } catch (err) {
    console.log(err)
  }
}

watch([selectedBrand, selectedYear], ([newBrand, newYear]) => {
  if (newBrand && newYear) {
    fetch_models(newBrand, newYear)
    selectedModel.value = '' // Reset selected model
    carDetails.value = [] // Reset car details
  } else {
    // Reset models if Brand and Year changed
    carModels.value = []
    selectedModel.value = ''
    carDetails.value = [] // Reset car details
  }
})

// Fetch Details
const selectedDetail = ref('')
const carDetails = ref([])
const fetch_details = async (year, model) => {
  const queryParams = {
    year: year,
    model: model.model_code,
  };

  try {
    const response = await axios.get(apiUrl + 'car-details', { params: queryParams })
    carDetails.value = response.data
  } catch (err) {
    console.log(err)
  }
}

watch([selectedModel, selectedYear, selectedBrand], ([newModel, newYear, newBrand]) => {
  if (newModel && newYear && newBrand) {
    fetch_details(newYear, newModel, newBrand)
    selectedDetail.value = ''
  } else {
    selectedDetail.value = ''
    carDetails.value = []
  }
})

// Computed property to check if all fields are selected
const isFormComplete = computed(() => {
  return selectedYear.value && selectedBrand.value && selectedModel.value && selectedDetail.value && selectedProvince.value;
})

const router = useRouter();

const carTypeMapping = {
  "เก๋ง": "110",
  "รถตู้": "210",
  "กระบะ": "320"
};

// Find voluntary code
const findVoluntaryCode = (bodyType) => {
  for (const [key, value] of Object.entries(carTypeMapping)) {
    if (bodyType.includes(key)) {
      return value;
    }
  }
  return null; // Return null or a default value if no match is found
};

const handleSubmit = async (event) => {
  event.preventDefault();

  // Query parameters
  const modelId = selectedModel.value.id;
  const year = selectedYear.value;
  const insuranceType = selectedPackage.value;
  const province = selectedProvince.value;
  const vehicleKey = selectedDetail.value.vehicle_key;
  const sum_insured = selectedDetail.value.max_sum_insured;
  const bodyType = selectedDetail.value.body_type;
  const voluntaryCode = findVoluntaryCode(bodyType);

  // Display Data
  const brand = selectedBrand.value.brand
  const model = selectedModel.value.model
  const spec = selectedDetail.value.model_spec

  // Navigate to the query page
  router.push({
    name: 'premium',
    query: {
      model_id: modelId,
      year: year,
      insuranceType: insuranceType,
      vehicle_key: vehicleKey,
      province: province,
      voluntary_code: voluntaryCode,
      sum_insured: sum_insured,
      brand: brand,
      model: model,
      spec: spec,
    }
  });
};
</script>

<template>

  <div class="sec1">
    <br /><br /><br /><br /><br />
    <div class="container">
      <div class="row">
        <div class="item item-1"></div>
        <div class="item item-2">
          <form name="form1" id="form1" @submit=handleSubmit>
            <div class="checkprem">
              <span class="check_title">เช็คเบี้ย</span class="check_title">
            </div>
            <!-- onchange="populate(this.id, 'models')" -->
            <select name="package" id="package" v-model="selectedPackage">
              <option value="" selected="selected">ประเภทเบี้ย</option>
              <option value="1">1</option>
              <option value="5">2+</option>
              <option value="6">3+</option>
            </select>
            <br /><br />

            <select name="year" id="year" v-model="selectedYear">
              <option value="" selected="selected" disabled>ปีรถยนต์</option>
              <option v-for="year in years" :value="year.year">{{ year.display }}</option>
            </select>
            <br /><br />
            <select name="brand" id="brand" v-model="selectedBrand">
              <option value="" selected="selected" disabled>ยี่ห้อรถยนต์</option>
              <option v-for="brand in brands" :value="brand">{{ brand.brand }}</option>
            </select>
            <br /><br />

            <select name="model" id="model" v-model="selectedModel">
              <option value="" selected="selected" disabled>รุ่นรถยนต์</option>
              <option v-for="model in carModels" :value="model">{{ model.model }}</option>
            </select>
            <br /><br />
            <!-- {{ carDetails }} -->
            <select name="car-details" id="car-details" v-model="selectedDetail">
              <option value="" selected="selected" disabled>รุ่นย่อย</option>
              <option v-for="detail in carDetails" :value="detail">{{ detail.model_spec }}</option>
            </select>
            <br /><br />

            <select name="province" id="province" v-model="selectedProvince">
              <option value="" selected="selected" disabled>จังหวัด</option>
              <option v-for="prov in province" :value="prov.province_th">{{ prov.province_th }}</option>
            </select>
            <br /><br />

            <div class="inpbutton">
              <input type="submit" value="ค้นหา" :disabled="!isFormComplete" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<!-- <script>
var subjectObject = {
  "Front-end": {
    "HTML": ["Links", "Images", "Tables", "Lists"],
    "CSS": ["Borders", "Margins", "Backgrounds", "Float"],
    "JavaScript": ["Variables", "Operators", "Functions", "Conditions"]
  },
  "Back-end": {
    "PHP": ["Variables", "Strings", "Arrays"],
    "SQL": ["SELECT", "UPDATE", "DELETE"]
  }
}
window.onload = function () {
  var subjectSel = document.getElementById("subject");
  var topicSel = document.getElementById("topic");
  var chapterSel = document.getElementById("chapter");
  for (var x in subjectObject) {
    subjectSel.options[subjectSel.options.length] = new Option(x, x);
  }

  subjectSel.onchange = function () {
    //empty Chapters- and Topics- dropdowns
    chapterSel.length = 1;
    topicSel.length = 1;
    //display correct values
    for (var y in subjectObject[this.value]) {
      topicSel.options[topicSel.options.length] = new Option(y, y);
    }
  }
  topicSel.onchange = function () {
    //empty Chapters dropdown
    chapterSel.length = 1;
    //display correct values
    var z = subjectObject[subjectSel.value][this.value];
    for (var i = 0; i < z.length; i++) {
      chapterSel.options[chapterSel.options.length] = new Option(z[i], z[i]);
    }
  }
}
</script> -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&family=Noto+Sans+Thai:wght@100..900&display=swap');

.noto-sans-thai {
  font-family: "Noto Sans Thai", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.check_title {
  font-family: Noto Sans Thai;
  font-size: 2.5rem;
  font-weight: 700;
  background: -webkit-linear-gradient(#c59a01, #564504);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sec1 {
  width: 100%;
  background-image: url("https://car-images.bauersecure.com/wp-images/164826/best_electric_luxury_car_bmw_i7.jpg");
  background-size: cover;
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  flex-grow: 1;
  height: 600px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.item {
  background-color: #161A30;
  border-block-color: black;
  border-radius: 20px;
  margin-left: 10px;
  padding: 10px;
  height: 470px;
  position: relative;
  flex: 1;
  box-shadow: 0 0 5px rgb(246, 243, 243);
}

.container {
  display: flex;
  justify-content: space-around;
  /* align-items: flex-start; */
  align-content: center;
}

.item-1 {
  opacity: 0%;
}

.item-2 {
  opacity: 95%;
}

@media (max-width: 800px) {
  .item-1 {
    flex: 100%;
    max-width: 0px;
    max-height: 0px;
  }
}

select {
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 2px black;
  border: 0px;
  font-family: Noto Sans Thai;
  border: #F0ECE5;
}

input[type="submit"] {
  width: 20%;
  padding: 5px;
  margin: -10px 0;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #ccc;
  font-family: "Noto Sans Thai";
  font-size: 1rem;
  font-weight: bolder;
  transition: all 0.3s ease;
}

select:focus,
input[type="submit"]:focus {
  outline: none;
  border-color: #c59a01;
}

input[type="submit"] {
  background-color: #c59a01;
  color: white;
  cursor: pointer;
}

input[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.inpbutton {
  display: flex;
  justify-content: center;
}

.checkprem {
  text-align: center;
  color: white;
  font-family: Noto Sans Thai;
}
</style>