<template>
    <body>
    <section id="intro">
        <div class="split-beer">
        <a href="https://lin.ee/aJts7d3" target="_blank">
        <img class="beer"
          src="@/assets/ads/wman.png" />
        </a>
        <a href="https://lin.ee/aJts7d3" target="_blank">
          <img class="beers" src="@/assets/ads/scanline.png">
        </a>


        
      </div>
      <div class="info">
        <h1 style="color: #31304D;">แอด Line Official Account</h1>
        <p style="color: #31304D; font-size: 2rem;">
          เพื่อทราบโปรโมชันดีๆ เพียงแค่ SCAN หรือ คลิก ได้เลย!
        </p>
        <a href="https://lin.ee/aJts7d3" target="_blank" class="button explore">ID: @ISRBROKER</a>
      </div>
      
    </section>

</body>
</template>

<script setup>

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&family=Noto+Sans+Thai:wght@100..900&display=swap');

.noto-sans-thai {
  font-family: "Noto Sans Thai", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.info {
  font-family: "Noto Sans Thai";
}
/* @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&family=Young+Serif&display=swap"); */

/* -------------- */
/* DEFAULT VALUES */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans Thai",;
  color: antiquewhite;
}

html {
  scroll-behavior: smooth;
  background-color: #f9f8fb;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  /* background-image: radial-gradient(at 9.2% 73.7%, #ffffff 0px, transparent 50%),
    radial-gradient(at 92.3% 7.6%, #fbf5ff 0px, transparent 50%); */
  /* background-repeat: no-repeat; */
}

/* ------ */
/* INTRO */

#intro {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  padding: 40px;
  min-height: 20dvh;
  overflow: hidden;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 30px 50px;
}

.info h1 {
  font-family: "Noto Sans Thai", bold;
  font-weight: 1500;
  text-align: center;
  font-size: 3rem;
}

.info p {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.split-beer {
  display: flex;
  flex-direction: row;
}

.beer {
  max-width: 100%;
  width: 350px;
  border-radius: 20px;
  margin-left: 10%;  
  /* border: 2px solid orange; */
}.beers {
  max-width: 100%;
  width: 300px;
  border-radius: 20px;
  /* margin-top: 200px; */
  /* border: 2px solid orange; */
}

a:nth-child(2) {
  transform: translateY(-1.5rem);
}

.button:after,
.close-btn:after {
  background: #fff;
}

.explore,
.explore:before,
.close-btn,
.close-btn:before {
  background: rgb(68, 255, 0);
  background: linear-gradient(
    45deg,
    rgb(58, 120, 1) 0%,
    rgb(13, 81, 1) 100%
  );
  background: -moz-linear-gradient(
    45deg,
    rgb(58, 120, 1) 0%,
    rgb(13, 81, 1) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgb(58, 120, 1) 0%,
    rgb(13, 81, 1) 100%
  );
}

.button {
  display: inline-block;
  position: relative;
  border-radius: 10px;
  text-decoration: none;
  padding: 0.8rem 2rem;
  font-size: 2rem;
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.5s ease;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button:hover {
  text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.8);
}

.button:hover::after {
  left: 100%;
  top: 100%;
  bottom: 100%;
  right: 100%;
}

.button::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: inherit;
  transition: all 0.5s;
}

.button::after {
  content: "";
  display: block;
  position: absolute;
  left: 2.5px;
  top: 2px;
  bottom: 2.5px;
  right: 2px;
  z-index: -1;
  border-radius: 8px;
  transition: all 0.5s;
}

/* -------- */
/* CONTENT */

#content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  padding: 60px 40px;
  min-height: 40dvh;
  overflow: hidden;
}

/* ------ */
/* MEDIA QUERIES */

@media (max-width: 1100px) {
  .beers {
    width: 200px;
  }

  #content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 800px) {
  #intro {
    padding: 30px;
  }

  .beer {
    width: 300px;
  }

  .info {
    gap: 20px;
    padding: 30px 30px;
  }

  .info h1 {
    font-size: 2.5rem;
  }

  .info p {
    font-size: 1rem;
  }

  #content {
    gap: 30px;
    padding: 60px 20px;
  }

  .card {
    width: 250px;
  }
}

@media (max-width: 550px) {
  #intro {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
    "info"
      "split-beer"
      ;
  }

  .info {
    grid-area: info;
  }

  .split-beer {
    grid-area: split-beer;
    margin: 20px auto;
  }

  .beer {
    width: 150px;
  }

  #content {
    grid-template-columns: repeat(1, 1fr);
    padding: 40px 40px;
  }

  .button {
    font-size: 1.5rem;
  }
}

</style>