<script setup>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import MyNavbar from "./components/MyNavbar.vue";
import footered from "./components/MyFooter.vue";
import { RouterView } from "vue-router";
</script>
<template>
  <div
    class="app"
    style="background: linear-gradient(180deg, #ffff , #ffff)"
  >
    <MyNavbar />
    <RouterView />
  </div>
  <!---- FOOTER ---->
  
  <footered />
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      message: "",
    };
  },
};

</script>

<style scoped>
/* .theme_used {
	color: #161A30;
	color: #31304D;
	color: #B6BBC4;
	color: #F0ECE5;
} */
* {
  overflow: hidden; /* Hide scrollbars */
  font-family: "Mitr", sans-serif;
}
</style>
