import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import QueryPremium from '../views/QueryPremium';
import HealthView from '../views/HealthView.vue'
import TypeOne from '../views/TypeOne.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/premium',
    name: 'premium',
    component: QueryPremium
  },
  {
    path: '/health',
    name: 'health',
    component: HealthView
  },
  {
    path: '/typeone',
    name: 'typeone',
    component: TypeOne
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
