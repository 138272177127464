<template>
  <body>
    <div>
      <span class="title1">PROMOTION</span><br><br>
    </div>

    <section id="content">
      <div class="card-hover">
        <div class="card-hover__content">
          <h3 class="card-hover__title">
            ประกันชั้น 1 <br />ผ่อน <span> 0%</span><br />
            สูงสุด <span>10</span> เดือน
          </h3>
          <p class="card-hover__text">
            ✅ ซื้อง่าย ตลอด 24 ชม.<br />
            ✅ ผ่อนได้ 0% สูงสุด 10 เดือน<br />
            ✅ ศูนย์บริการ/อู่ซ่อมในเครือ คุณภาพกว่า 1,000 แห่ง<br />
            ✅ รับบริการช่วยเหลือฉุกเฉิน 24 ชม.<br />
          </p>
                      <a href="#" class="card-hover__link">
            <span>สนใจ</span>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </a>
          
        </div>
        <div class="card-hover__extra">
          <h4><br>รับบัตร<span>เงินสด</span> มูลค่า<br> <span>500</span> บาท!</h4>
        </div>
        <img class="card-img" src="@/assets/promotion/pro1.png" />
      </div>
      <div class="card-hover">
        <div class="card-hover__content">
          <h3 class="card-hover__title">
          ร้อนๆแบบนี้🚗<br> มารับโปรเย็นใจกับเราดีกว่า 
          </h3>
          <p class="card-hover__text">
☑️ความคุ้มครองสูง<br>
☑️เช็คเบี้ยต่ออายุได้ทันที<br>
☑️ชำระเงินสด รับฟรีของแถมอีกมากมาย<br><br>
          </p>
                      <a href="#" class="card-hover__link">
            <span>สนใจ</span>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </a>
          
        </div>
        <div class="card-hover__extra">
          <h4><br>รับบัตร<span>เงินสด</span> มูลค่า<br> <span>500</span> บาท!</h4>
        </div>
        <img class="card-img" src="@/assets/promotion/pro2.png" />
      </div>
    </section>
  </body>
</template>

<script setup></script>

<style scoped>

html {
  box-sizing: border-box;
  font-size: 100%;
}

html,
body {
  height: 100%;
  font-family: mitr;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 100%;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 0;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 100px;
  min-height: 100vh;
  color: #F0ECE5;
  background-color: #ffffff;
}
#content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* gap: 50px; */
  /* padding: 60px 40px; */
  /* min-height: 40dvh; */
  overflow: hidden;
}
.title1 {
  font-family: mitr;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  background: -webkit-linear-gradient(#161A30, #48556b);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-hover {
  margin-left: 10px;
  width: 320px;
  height: 500px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 32px -10px rgba(0, 0, 0, 0.08);
}
.card-hover:has(.card-hover__link:hover) .card-hover__extra {
  transform: translateY(0);
  transition: transform 0.35s;
}
.card-hover:hover .card-hover__content {
  background-color: #31304D;
  bottom: 100%;
  transform: translateY(100%);
  padding: 50px 60px;
  transition: all 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
}
.card-hover:hover .card-hover__link {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: all 0.3s 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
}
.card-hover:hover img {
  transform: scale(1);
  transition: 0.35s 0.1s transform cubic-bezier(0.1, 0.72, 0.4, 0.97);
}
.card-hover__content {
  width: 100%;
  text-align: center;
  background-color: #31304D;
  padding: 0 60px 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(0);
  transition: all 0.35s 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
  will-change: bottom, background-color, transform, padding;
  z-index: 1;
}
.card-hover__content::before,
.card-hover__content::after {
  content: "";
  width: 100%;
  height: 120px;
  background-color: inherit;
  position: absolute;
  left: 0;
  z-index: -1;
}
.card-hover__content::before {
  top: -80px;
  -webkit-clip-path: ellipse(60% 80px at bottom center);
  clip-path: ellipse(60% 80px at bottom center);
}
.card-hover__content::after {
  bottom: -80px;
  -webkit-clip-path: ellipse(60% 80px at top center);
  clip-path: ellipse(60% 80px at top center);
}
.card-hover__title {
  font-size: 1.5rem;
  margin-bottom: 1em;
}
.card-hover__title span {
  color: #ffff02;
}
.card-hover__text {
  font-size: 0.75rem;
  text-align: left;
  margin-left: 10px
}
.card-hover__link {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 10%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: bold;
  color: #f9f8fb;
  opacity: 0;
  padding: 10px;
  transition: all 0.35s;
}
.card-hover__link:hover svg {
  transform: translateX(4px);
}
.card-hover__link svg {
  width: 18px;
  margin-left: 4px;
  transition: transform 0.3s;
}
.card-hover__extra {
  height:40%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
  background-color: #31304D;
  padding: 80px;
  bottom: 0;
  z-index: 1;
  color: #F0ECE5;
  transform: translateY(100%);
  will-change: transform;
  transition: transform 0.35s;
}
.card-hover__extra span {
  color: #ffbd06;
}
.card-hover img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  z-index: 0;
  transform: scale(1.2);
  transition: 0.35s 0.35s transform cubic-bezier(0.1, 0.72, 0.4, 0.97);
}

@media (max-width: 660px) {
  #content {
    grid-template-columns: repeat(1, 1fr);
  }
  .card-hover {
    margin-top: 20px;
  }
}

</style>
