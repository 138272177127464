<template>
  <br><br><br><br>
  <div>
    <!-- Loading Screen -->
    <LoadingScreen v-if="loading" />

    <!-- Responsive Filter Section -->
    <div class="responsive-filter-bar">
      <div class="filter-button drop-down" @click="toggleFilterDetail('garage')">
        ประเภทการซ่อม <span>{{ translate(selectedGarage) || 'ทั้งหมด' }}</span>
      </div>
      <div v-if="visibleFilterDetail === 'garage'" class="filter-dropdown">
        <div class="filter-option" @click="selectGarage('ทั้งหมด')">ทั้งหมด</div>
        <div class="filter-option" @click="selectGarage('contracted')">ซ่อมอู่</div>
        <div class="filter-option" @click="selectGarage('dealer')">ซ่อมห้าง</div>
      </div>

      <div class="filter-button drop-down" @click="toggleFilterDetail('deduct')">
        ค่าซ่อมแรก <span>{{ selectedDeduct || 'ทั้งหมด' }}</span>
      </div>
      <div v-if="visibleFilterDetail === 'deduct'" class="filter-dropdown">
        <div class="filter-option" @click="selectDeduct('')">ทั้งหมด</div>
        <div class="filter-option" @click="selectDeduct('0')">0</div>
        <div class="filter-option" @click="selectDeduct('3000')">3,000</div>
        <div class="filter-option" @click="selectDeduct('5000')">5,000</div>
      </div>

      <div class="filter-button" @click="toggleFilterDetail('sort')">
        เรียงผลตาม <span>
          {{ sortKey === 'premium' ? 'เบี้ยประกัน' : 'ทุนประกัน' }}
          <span v-html="getSortArrow(sortKey)"></span>
        </span>
      </div>
      <div v-if="visibleFilterDetail === 'sort'" class="filter-dropdown">
        <div class="filter-option" @click="updateSort('premium', 'asc')">เบี้ยประกัน (ต่ำไปสูง)</div>
        <div class="filter-option" @click="updateSort('premium', 'desc')">เบี้ยประกัน (สูงไปต่ำ)</div>
        <div class="filter-option" @click="updateSort('sumInsured', 'asc')">ทุนประกัน (ต่ำไปสูง)</div>
        <div class="filter-option" @click="updateSort('sumInsured', 'desc')">ทุนประกัน (สูงไปต่ำ)</div>
      </div>
    </div>

    <!-- Original Filter Section (Hidden on Mobile) -->
    <div class="filter-section">
      <div>
        <h5>ตัวกรองข้อมูล</h5>
        <div>
          <label for="deduct">ค่าเสียหายส่วนแรก:</label>
          <select id="deduct" v-model="selectedDeduct" @change="applyFilters">
            <option value="">ทั้งหมด</option>
            <option value="0">0</option>
            <option value="3000">3,000</option>
            <option value="5000">5,000</option>
          </select>
        </div>
        <div>
          <label for="garage">ประเภทซ่อม:</label>
          <select id="garage" v-model="selectedGarage" @change="applyFilters">
            <option value="">ทั้งหมด</option>
            <option value="contracted">ซ่อมอู่</option>
            <option value="dealer">ซ่อมห้าง</option>
          </select>
        </div>
      </div>

      <div>
        <h5>เรียงราคา</h5>
        <div>
          <button :class="{ 'active': sortKey === 'premium' }" @click="updateSort('premium')">
            เบี้ยประกัน
            <span v-if="sortKey === 'premium'" v-html="getSortArrow('premium')"></span>
          </button>
          <button :class="{ 'active': sortKey === 'sum_insured' }" @click="updateSort('sum_insured')">
            ทุนประกัน
            <span v-if="sortKey === 'sum_insured'" v-html="getSortArrow('sum_insured')"></span>
          </button>
        </div>

      </div>
    </div>

    <div class="prembg">
      <div class="side">
        <p class="search-result-2">
          <span>{{ route.query.brand }} {{ route.query.model }} {{ route.query.year }} / {{ route.query.spec }} / {{ route.query.province }}</span>
        </p>
        <p class="search-result">พบผลการค้นหา {{ visiblePremiums.length }} รายการ</p>
        <br /><br />
      </div>

      <!-- Premium content -->
      <div class="grid-container">
        <div class="grid-item" v-for="(prem, index) in visiblePremiums" :key="index">
          <div class="card">
            <div class="card-header">
              <img :src="getLogo(prem.package.company)" alt="Company Logo" class="company-logo" />
              <div class="company-info">
                <h6>{{ prem.package.company }}</h6>
                <p>ประกันภัยรถยนต์ชั้น {{ route.query.insuranceType }} | {{ translate(prem.garage) }}</p>
              </div>
            </div>
            <div class="card-body">
              <div class="info-section">
                <div class="info-left">
                  <p class="info-label">ค่าเสียหายส่วนแรก:</p>
                  <p class="info-value">฿{{ prem.deduct }}</p>
                  <p class="info-label">ทุนประกันภัย:</p>
                  <p class="info-value">฿{{ prem.max_sum_insured }}</p>
                </div>
                <div class="info-right">
                  <p class="info-label">เบี้ยประกัน/ปี:</p>
                  <p class="premium-price">฿{{ prem.premium_total }}</p>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button class="btn btn-secondary" v-if="isFirstPremium(prem)"
                @click="toggleCompanyVisibility(prem.package.company)">
                {{ expandedCompanies.has(prem.package.company) ? 'ดูน้อยลง' : 'ดูแผนเพิ่มเติม' }}
              </button>
              <button class="btn btn-primary" @click="showDetails(prem)">รายละเอียด</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Component -->
    <DetailModal :visible="showModal" :packageData="selectedPackage" :sumInsured="route.query.sum_insured"
      @close="showModal = false" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import LoadingScreen from '../components/LoadingScreen.vue';
import DetailModal from '../components/DetailModal.vue';

const premiums = ref([]);
const route = useRoute();
const loading = ref(true);
const showModal = ref(false);
const selectedPackage = ref(null);
const expandedCompanies = ref(new Set());

// Filter options
const selectedDeduct = ref('');
const selectedGarage = ref('');
const visibleFilterDetail = ref(null);

// Sorting options
const sortKey = ref('premium'); // Default sorting by premium price
const sortDirection = ref('asc'); // Default sort direction

const toggleFilterDetail = (filterType) => {
  visibleFilterDetail.value = visibleFilterDetail.value === filterType ? null : filterType;
};

const selectGarage = (garageType) => {
  selectedGarage.value = garageType;
  applyFilters();
  visibleFilterDetail.value = null; // Close the dropdown
};

const selectDeduct = (deduct) => {
  selectedDeduct.value = deduct;
  applyFilters();
  visibleFilterDetail.value = null; // Close the dropdown
};

const fetchPremiums = async () => {
  const queryParams = {
    model_id: route.query.model_id,
    year: route.query.year,
    insurance_type: route.query.insuranceType,
    voluntary_code: route.query.voluntary_code,
    vehicle_key: route.query.vehicle_key,
    province: route.query.province,
    sum_insured: route.query.sum_insured
  };
  const apiUrl = 'https://quick-brake-production.up.railway.app/api/premiums_API/';

  try {
    const response = await axios.get(apiUrl, { params: queryParams });
    premiums.value = [...response.data.API_package, ...response.data.Database_package].map(prem => {
      return {
        ...prem,
        premium_total: parseInt(prem.premium_total, 10), // Convert to integer
      };
    });
  } catch (err) {
    console.error(err);
  } finally {
    loading.value = false;
  }
};

const showDetails = (packageData) => {
  selectedPackage.value = packageData;
  showModal.value = true;
};

const translate = (key) => {
  return translations[key] || key;
};

const getLogo = (companyName) => {
  switch (companyName) {
    case 'Chubb':
      return '/chubb_logo.png';
    case 'AXA':
      return '/AXA_logo.png';
    default:
      return '';
  }
};

const translations = {
  "contracted": "ซ่อมอู่",
  "dealer": "ซ่อมห้าง",
  "Contracted": "ซ่อมอู่",
  "Dealer": "ซ่อมห้าง"
};

const toggleCompanyVisibility = (company) => {
  if (expandedCompanies.value.has(company)) {
    expandedCompanies.value.delete(company);
  } else {
    expandedCompanies.value.add(company);
  }
};


const isFirstPremium = (prem) => {
  const companyPremiums = visiblePremiums.value.filter(p => p.package.company === prem.package.company);
  // Determine if the given premium is the first one in the sorted list for its company
  return companyPremiums.length > 0 && companyPremiums[0] === prem;
};


const applyFilters = () => {
};

const updateSort = (key, direction = null) => {
  if (direction === null) {
    // Toggle sort direction if the key is the same
    if (sortKey.value === key) {
      sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
    } else {
      // Set new sort key and default direction to 'asc'
      sortKey.value = key;
      sortDirection.value = 'asc';
    }
  } else {
    // Set both sort key and direction if provided
    sortKey.value = key;
    sortDirection.value = direction;
  }

  visibleFilterDetail.value = null; // Close the dropdown
  applyFilters(); // Apply filters to update visible premiums
};

const getSortArrow = (key) => {
  if (sortKey.value === key) {
    return sortDirection.value === 'asc' ? '&#9650;' : '&#9660;';
  }
  return '&#9650;';
};

const sortedPremiums = computed(() => {
  return [...premiums.value].sort((a, b) => {
    const valueA = sortKey.value === 'premium' ? a.premium_total : a.max_sum_insured;
    const valueB = sortKey.value === 'premium' ? b.premium_total : b.max_sum_insured;
    return sortDirection.value === 'asc' ? valueA - valueB : valueB - valueA;
  });
});


const visiblePremiums = computed(() => {
  // Step 1: Filter the sorted premiums
  const filtered = sortedPremiums.value.filter(prem => {
    const matchesDeduct = !selectedDeduct.value || Number(prem.deduct) === Number(selectedDeduct.value);
    const matchesGarage = !selectedGarage.value || prem.garage.toLowerCase() === selectedGarage.value;
    return matchesDeduct && matchesGarage;
  });

  // Step 2: Create a map to keep track of the highest sum insured for each company and premium total
  const premiumMap = new Map();

  filtered.forEach(prem => {
    const key = `${prem.package.company}-${prem.premium_total}`;
    if (!premiumMap.has(key)) {
      premiumMap.set(key, prem);
    } else {
      const existing = premiumMap.get(key);
      if (prem.max_sum_insured > existing.max_sum_insured) {
        premiumMap.set(key, prem);
      }
    }
  });

  // Step 3: Convert the map values to an array for the final list of visible premiums
  const visible = Array.from(premiumMap.values());

  // Step 4: Handle company count logic
  const companyCount = {};
  const finalVisible = [];

  visible.forEach(prem => {
    const company = prem.package.company;
    if (!companyCount[company]) {
      companyCount[company] = 0;
    }

    if (companyCount[company] === 0 || expandedCompanies.value.has(company)) {
      finalVisible.push(prem);
    }

    companyCount[company]++;
  });

  return finalVisible;
});


onMounted(fetchPremiums);
</script>

<style scoped>
/* Sidebar and Layout styles */
.prembg {
  min-height: 800px;
  background-color: #ffff;
}

.responsive-filter-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  position: relative;
  /* Needed for absolute positioning of dropdowns */
}

.filter-button {
  padding: 10px 3px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f8f9fa;
  cursor: pointer;
  text-align: center;
  flex: 1;
  margin: 0 5px;
  position: relative;
  /* Needed for dropdown positioning */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}

.filter-button.drop-down::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #242a4e; /* Arrow color */
  margin-left: 10px; /* Space between text and arrow */
}

.filter-button span {
  font-weight: bold;
  color: #242a4e;
  font-size: 16px;
}

.filter-dropdown {
  position: absolute;
  top: 100%;
  /* Place it directly below the button */
  left: 0;
  right: 0;
  margin-top: 2px;
  /* Small gap between button and dropdown */
  background-color: #fff;
  border: 1px solid #ddd;
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* Ensure the dropdown is the same width as the button */
}

.filter-option {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.filter-option:last-child {
  border-bottom: none;
  /* Remove border from the last option */
}

.filter-option:hover {
  background-color: #f0f0f0;
}

.filter-section {
  display: none;
  /* Hide the original filter section on mobile */
}

@media (min-width: 992px) {
  .filter-section {
    display: block;
    /* Show the original filter section on larger screens */
  }

  .responsive-filter-bar,
  .filter-details {
    display: none;
    /* Hide the new filter bar on larger screens */
  }
}

.filter-section {
  margin-left: 15px;
  padding: 20px;
  width: 225px;
  background-color: #f8f9fa;
  position: fixed;
  height: auto;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-section h5 {
  margin-bottom: 15px;
}

.filter-section select {
  margin-bottom: 20px;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

/* Styles for active sort button */
.filter-section button.active {
  background-color: #161A30;
  color: white;
  border: 1px solid #161A30;
}

.filter-section button.active span {
  color: white;
}

.filter-section button.active:hover {
  background-color: #242a4e;
  border-color: #242a4e;
}

/* Filter Section */
.filter-section {
  margin-left: 15px;
  padding: 20px;
  width: 225px;
  background-color: #f8f9fa;
  position: fixed;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-section h5 {
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.filter-section select {
  margin-bottom: 20px;
  width: 100%;
  padding: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.filter-section button {
  background-color: #cbccd4;
  color: #161A30;
  border: 1px solid;
}

.filter-section button:hover {
  background-color: rgba(235, 235, 235, 0.856);
}

.filter-section button span {
  float: right;
}

.search-result {
  padding: 10px;
  background-color: hsla(34, 54%, 73%, 0.478);
}

.search-result-2 {
  background-color: #e9ecef;
  color: #343a40;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.query-label {
  font-weight: 300;
  /* Light font weight for labels */
}

.query-value {
  font-weight: 600;
  /* Bold font weight for values */
}

.side {
  margin-left: 280px;
  font-size: 12px;
}

/* Grid layout styles */
.grid-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 280px;
  padding: 20px;
}

.grid-item {
  width: 22%;
  margin: 10px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-header {
  display: flex;
  align-items: center;
}

.company-logo {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.company-info {
  text-align: left;
}

.company-info h6 {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.company-info p {
  margin: 0;
  font-size: 0.8rem;
}

.card-body {
  margin-top: 10px;
}

.info-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-left {
  text-align: left;
}

.info-right {
  text-align: right;
}

.info-label {
  margin-top: 15px;
  margin-bottom: 2px;
}

.info-value {
  margin-top: 2px;
  margin-bottom: 5px;
  font-weight: 500;
}

.premium-price {
  font-size: 30px;
  font-weight: 500;
  color: rgb(201, 141, 0);
}

.card-footer {
  margin-top: 20px;
}

.btn {
  width: 100%;
  margin-top: 10px;
}

.btn-secondary {
  background-color: #F5F5F5;
  color: #161A30;
  border: 1px solid #161A30;
}

.btn-primary {
  background-color: #161A30;
  color: white;
  border: none;
}

/* Responsive Styles */
@media (max-width: 1860px) {
  .grid-item {
    width: 30%;
  }
}

/* Responsive Styles */
@media (max-width: 1450px) {
  .grid-item {
    width: 35%;
  }
}

@media (max-width: 1280px) {
  .grid-item {
    width: 47%;
  }
}


@media (max-width: 991px) {
  .side {
    margin-left: 0;
  }

  .grid-container {
    margin-left: 0;
  }

  .grid-item {
    width: 31%;
  }

  .company-info h6 {
    font-size: 1rem;
  }

  .info-left,
  .info-right {
    font-size: 0.9rem;
  }

  .premium-price {
    font-size: 26px;
  }

  .card {
    padding: 15px;
  }
}

@media (max-width: 939px) {
  .grid-item {
    width: 45%;
  }

  .btn {
    font-size: 0.9rem;
    padding: 8px 12px;
  }

  .company-info h6 {
    font-size: 1rem;
  }

  .premium-price {
    font-size: 24px;
  }
}

@media (max-width: 748px) {
  .grid-item {
    width: 100%;
  }

  .btn {
    font-size: 0.8rem;
    padding: 6px 10px;
  }

  .company-info h6 {
    font-size: 0.9rem;
  }

  .premium-price {
    font-size: 20px;
  }

  .card {
    padding: 10px;
  }
}
</style>