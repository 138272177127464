<template>
  <div class="bg">
    <div class="container content">
    <div class="row">
      <div class="card_wrapper1">
        <div class="grid-container">
          <div class="whyT1">
            <h1>
              <span class="partnertext">พันธมิตรของเรา</span>
            </h1>
          </div>
        </div>
      <div class="grid-containerT1">
        <div>
          <div class="row">
            <div class="grid-containers">
              <div class="boxlogo"><img src="@/assets/logo_insure/allianz.jpg" class="logo-1" alt=""></div>
              <div class="boxlogo"><img src="@/assets/logo_insure/ergo.jpg" class="logo-1" alt=""></div>
              <div class="boxlogo"><img src="@/assets/logo_insure/kpi.png" class="logo-1" alt=""></div>
              <div class="boxlogo"><img src="@/assets/logo_insure/ioi.png" class="logo-1" alt=""></div>
              <div class="boxlogo"><img src="@/assets/logo_insure/viriya.png" class="logo-1" alt=""></div>
              <div class="boxlogo"><img src="@/assets/logo_insure/indara.png" class="logo-1" alt=""></div>
              <div class="boxlogo"><img src="@/assets/logo_insure/tokio.jpg" class="logo-1" alt=""></div>
              <div class="boxlogo"><img src="@/assets/logo_insure/deves.png" class="logo-1" alt=""></div>
              <div class="boxlogo"><img src="@/assets/logo_insure/axa.png" class="logo-1" alt=""></div>
              <div class="boxlogo"><img src="@/assets/logo_insure/chubb.png" class="logo-1" alt=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br><br><br>
  </div>

  <!-- <button class="collapsible">พันธมิตรของเรา</button> -->

</template>


<style scoped>
template {
  font-family: mitr;

}
.bg {
  background-color: #F0ECE5;
  height: 100%;
}
.partnertext {
  text-align: left;
  font-size: 2rem;
  text-decoration: underline;
  color: #13112a;
}
.boxlogo {
  border-radius: 20px;
  margin-right: 5px;
  margin-left: 5px;
}
.grid-containerT1 {
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  margin-left: 20px;

}
.card_wrapper1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 3%;
}
.grid-containers {
  display: grid;
  grid-template-columns: auto auto auto auto;
  font-size: 20px;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  padding: 10px;
}

.logo-1 {
  height: 80px;
  width: 80px;
  align-content: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.content {
  padding: 0 18px;
  max-height: 1200px;

}

@media screen and (min-width: 1401px) {
  .grid-containers{ grid-template-columns: repeat(5, 1fr); margin-left: 50px;}
  .boxlogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: rgb(0, 0, 0); */
    align-content: center;
    margin-left: 30px;
  }
  .logo-1 {
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width: 1400px) {
  .grid-containers{ grid-template-columns: repeat(5, 1fr); margin-left: 50px;}
  .logo-1 {
    width: 100px;
    height: 100px;
  }
  .boxlogo {
    margin-left: 25px;
  }
}
@media screen and (max-width: 1200px) {
  .grid-containers{ grid-template-columns: repeat(5, 1fr); margin-left: 20px;}
  .logo-1 {
    width: 80px;
    height: 80px;
  }
  .boxlogo {
    margin-left: 5px;
  }
}
@media screen and (max-width: 780px) {
  .grid-containers{ grid-template-columns: repeat(4, 1fr); margin-bottom: 15px; margin-left: 5px;}
  .partnertext {
  font-size: 1.5rem;
}
  .boxlogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 50px;
    height: 50px;
    margin: 10px;
  }
}
@media screen and (max-width: 500px) {
  .grid-containers{ grid-template-columns: repeat(4, 1fr); margin-bottom: 15px;}
  .boxlogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 50px;
    height: 50px;
    margin: 1px;
  }
  .logo-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 60px;
    height: 60px;
    margin: 10px;
  }
  .partnertext {
  font-size: 1.5rem;
}
}
@media screen and (max-width: 420px) {
  .grid-containers{ grid-template-columns: repeat(3, 1fr); margin-bottom: 15px;}
  .boxlogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin: 1px;
  }
  .logo-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 60px;
    height: 60px;
    margin: 10px;
  }
}
@media screen and (max-width: 350px) {
  .grid-containers{ grid-template-columns: repeat(2, 1fr); margin-bottom: 15px;}
  .boxlogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin: 1px;
  }
  .logo-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 60px;
    height: 60px;
    margin: 10px;
  }
}
</style>