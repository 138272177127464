<template>
  <head>
</head>
  <section class="about-section">
    <div class="container">
      <div class="row clearfix">
        <!--Content Column-->
        <div class="content-column col-md-6 col-sm-12 col-xs-12">
          <div class="inner-column">
            <div class="sec-title">
              <div class="title">เกี่ยวกับเรา</div>
              <br>
			  <span class="titles"> <span class="isr">อิสระอินเตอร์โบรกเกอร์</span></span>
            </div>
            <div class="text">
              ด้วยประสบการณ์ในธุรกิจประกันวินาศภัยมากกว่า 24 ปี มั่นใจได้กับบริการประกันภัยที่เหนือกว่า
              เพราะเรารู้ดีว่าประกันภัยคือสิ่งสำคัญ ด้วยประสบการณ์ของเราที่มีมากว่า 2 ทศวรรษ
			  ทีมงานของเราล้วนพร้อมให้คำปรึกษาและตอบคำถามทุกข้อข้องใจ บริการระดับมืออาชีพ
              ใส่ใจทุกรายละเอียด มอบประสบการณ์ใหม่ที่เหนือกว่าเราพร้อมสร้างความมั่นใจให้กับคุณ
              ด้วยบริการที่หลากหลาย ตรงกับความต้องการพบกับประสบการณ์ที่แตกต่าง
              สัมผัสบริการประกันภัยที่ไม่เหมือนใคร
            </div>
			<h2>
                "มั่นใจได้...<br />
                เพราะเราคือผู้เชี่ยวชาญ"
              </h2>
			  <br>
            <!-- <div class="email">
              Request Quote:
              <span class="theme_color">freequote@gmail.com</span>
            </div> -->
			<router-link to="/about" class="theme-btn btn-style-three">รายละเอียดเพิ่มเติม</router-link>
          </div>
        </div>

        <!--Image Column-->
        <div class="image-column col-md-6 col-sm-12 col-xs-12">
          <div
            class="inner-column"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <div class="image">
              <img src="@/assets/picture/about.png" alt="" style="border-radius: 10%;"/>
              <!-- <div class="overlay-box">
                <div class="year-box">
                  <span style="color:rgba(255, 255, 255, 0)">ประสบการณ์</span><br />
                  <span style="color:rgba(255, 255, 255, 0)">มากกว่า </span><br />
                  <span class="number">22 ปี</span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- thank https://codepen.io/baahubali92/pen/JwpXQX -->
</template>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&family=Noto+Sans+Thai:wght@100..900&display=swap');

.noto-sans-thai {
  font-family: "Noto Sans Thai", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.about-section {
  position: relative;
  padding: 120px 0px;
  font-family: Noto Sans Thai;
  background-color: hsl(0, 0%, 100%);
  min-height: 700px;
}
h2 {
	background: -webkit-linear-gradient(#000000, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.isr {
	font-size: 2rem;
	background: -webkit-linear-gradient(#000000, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-section .content-column {
  position: relative;
  margin-bottom: 40px;
}

.about-section .content-column .inner-column {
  position: relative;
  /* padding-top: 50px; */
  /* padding-right: 100px; */
}

.about-section .content-column .text {
  position: relative;
  color: #000000;
  font-size: 1rem;
  line-height: 2em;
  margin-bottom: 40px;
}

.about-section .content-column .email {
  position: relative;
  color: #252525;
  font-weight: 700;
  margin-bottom: 50px;
}

.about-section .image-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section .image-column .inner-column {
  position: relative;
  padding: 40px 40px 0px 0px;
  margin-left: 50px;
}

.about-section .image-column .inner-column:after {
  position: absolute;
  content: "";
  right: 0px;
  top: 0px;
  left: 40px;
  bottom: 100px;
  z-index: -1;
  border: 2px solid #d7a449;
}

.about-section .image-column .inner-column .image {
  position: relative;
}

.about-section .image-column .inner-column .image:before {
  position: absolute;
  content: "";
  left: -50px;
  bottom: -50px;
  width: 300px;
  height: 300px;
  /* background: url(img/pattern-2.png) no-repeat; */
  background-color: #00000000;
  /* border: 5px solid #d7a449; */
  border-radius: 10%;
}

.about-section .image-column .inner-column .image img {
  position: relative;
  width: 100%;
  display: block;
}

.about-section .image-column .inner-column .image .overlay-box {
  position: absolute;
  left: 40px;
  bottom: 48px;
}

.about-section .image-column .inner-column .image .overlay-box .year-box {
  position: relative;
  color: #252525;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4em;
  padding-left: 125px;
}
.about-section
  .image-column
  .inner-column
  .image
  .overlay-box
  .year-box
  .number {
  position: absolute;
  left: 80%;
  top: 0px;
  width: 110px;
  height: 110px;
  color: #d7a449;
  font-size: 3rem;
  font-weight: 700;
  line-height: 105px;
  text-align: center;
  background-color: #ffffff;
  /* border: 1px solid #000000; */
}
.text {
	text-align: left;
	color: #000000;
}
.number {
  border-radius: 100%;
  border: #ffffff;
  background-color: #252525;
}
.about-section .btn-style-three:before {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  z-index: -1;
  right: -10px;
  bottom: -10px;
  background: url(https://i.ibb.co/DKn55Qz/pattern-1.jpg) repeat;
}
.about-section .btn-style-three:hover {
  color: #ffffff;
  background: #d7a449;
}
.about-section .btn-style-three {
  position: relative;
  line-height: 24px;
  color: #1b164f;
  font-size: 1rem;
  font-weight: 700;
  background: none;
  display: inline-block;
  padding: 11px 40px;
  background-color: #ffffff;
  text-transform: capitalize;
  border: 2px solid #d7a449;
  font-family: "mitr";
}
.sec-title2 {
  color: #fff;
}
.sec-title {
  position: relative;
  padding-bottom: 40px;
}
.sec-title .title {
  position: relative;
  color: #d7a449;
  font-size: 2rem;
  font-weight: 700;
  padding-right: 50px;
  margin-bottom: 15px;
  display: inline-block;
  text-transform: capitalize;
}
.sec-title .title:before {
  position: absolute;
  content: "";
  right: 50px;
  bottom: 7px;
  width: 4000px;
  height: 3px;
  background-color: #ffffff;
}
.titles:before {
  position: absolute;
  content: "";
  left: 300px;
  bottom: 7px;
  width: 4000px;
  height: 3px;
  background-color: #ffff;
  color: #d7a449;
  font-size: 2rem;
}
</style>
