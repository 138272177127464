import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/style.css"
// const express = require('express');
const cors = require('cors');


// import { Quasar } from 'quasar'
// import quasarLang from 'quasar/lang/th'
// import '@quasar/extras/material-icons/material-icons.css'
// import 'quasar/src/css/index.sass'

createApp(App).use(store).use(router).use(cors).mount('#app')
// createApp(App).use(store).use(router).use(Quasar, {lang: quasarLang}).mount('#app')
// myApp.use(Quasar, {
//   plugins: {}, // import Quasar plugins and add here
//   lang: quasarLang,
// })

// // Assumes you have a <div id="app"></div> in your index.html
// myApp.mount('#app')
