<script setup>
</script>
<template>
<div class="flex">
<!-- <div class="card">
  <p class="">koenigsegg</p>
  <div class="card-button-wrapper">
    <div class="curve crv-bottom"></div>
    <div class="curve crv-right"></div>
    <div class="card-button"> 
      koenigsegg
    </div>
  </div>
</div>
<div class="card" style="--url:url(https://images.unsplash.com/photo-1566024164372-0281f1133aa6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1528&q=80);">
  <p class="">Lamborghini</p>
  <div class="card-button-wrapper">
    <div class="curve crv-bottom"></div>
    <div class="curve crv-right"></div>
    <div class="card-button"> 
      Lamborghini
    </div>
  </div>
</div> -->
<div class="card" style="--url:url(https://images.unsplash.com/photo-1662393997616-c8d292bfa5a8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWFjbGFyZW4lMjA1NzBzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60)">
  <p class="">Mc'Laren</p>
  <div class="card-button-wrapper">
    <div class="curve crv-bottom"></div>
    <div class="curve crv-right"></div>
    <div class="card-button"> 
      Mc'Laren
    </div>
  </div>
</div>
  </div>
</template>

<style scoped>
*{
  margin:0;
  padding:0;
}
:root{
  --card-width:600px;
  --card-height:400px;
  --background-color:#343434;
  --url:url('https://images.unsplash.com/photo-1596906673164-fffb05b8333d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80');
}
body{
  height:100vh;
  display:grid;
  place-items:center;
  background:#343434;
  font-family:sans-serif;
  text-transform:uppercase;
}

.flex{
  display:flex;
  flex-wrap:wrap;
  transition: all 1s ease;
}
.card{
  position:relative;
  width:600px;
  height:400px;
  background-image:linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.7625759776566876) 100%),url('https://images.unsplash.com/photo-1596906673164-fffb05b8333d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80');
  background-size:cover;
  background-position:center center;
  border-radius:calc( 0.15 * 400px);
  transition:all .2s ease-in-out;
  display:grid;
  place-items:center;
  color:#fff;
  font-weight:bolder;
  font-size:2rem;
  margin:.5em;
  
}
.card-button-wrapper{
  position:absolute;
  bottom:0;
  right:0;
  width:calc( 0.3 * 400px);
  height:calc( 0.3 * 400px);
  background:#343434;
  border-radius: calc( 0.2 * 400px) 0 0 0;
  padding:calc( 0.064 * 400px) 0 0 calc( 0.064 * 400px);
  
}
.card-button{
  display:grid;
  place-items:center;
  width:calc( 0.3 * 400px);
  height:calc( 0.3 * 400px);
  border-radius:50%;
  background:#fff;
  color:#000;
  bottom:0;
  right:0;
  font-size:calc( 0.03 * 400px);
  transition:all .2s ease-in-out;
}
.curve{
   
   width:calc( 0.15 * 400px);
  height:calc( 0.15 * 400px);
  background: radial-gradient(circle, rgba(255,0,0,0) calc(70% - 1px), #343434 70%, #343434 100%);
  background-position:calc( -0.15 * 400px) calc( -0.15 * 400px);
  background-repeat:repeat;
  background-size:200% 200%;
  
  
}
.crv-right{
 position:absolute;
  bottom:calc( 0.361 * 400px);
  right:-1px;
}
.crv-bottom{
 position:absolute;
  bottom:-1px;
  right:calc( 0.361 * 400px);
}

.card-button:hover{
    transform:rotate(-30deg);
  }  
.card-button:hover{
  display:grid;
  place-items:center;
  width:calc( 0.3 * 400px);
  height:calc( 0.3 * 400px);
  border-radius:50%;
  background:#fff;
  color:#000;
  bottom:0;
  right:0;
  font-size:calc( 0.03 * 400px);
  transition:all .2s ease-in-out;
}

</style>